import { getUpcomingInvoice , getInvoices } from '@icons8/common/src/api/billing';


export const state = () => ({
  nextPayment: null,
  invoices: null,
  subscribeTierLoader: null,
})

export const mutations = {
  SET_NEXT_PAYMENT(state, data) {
    state.nextPayment = data;
  },
  SET_INVOICES(state, data) {
    state.invoices = data;
  },
  SET_SUBSCRIBE_TIER_LOADER(state, tier) {
    state.subscribeTierLoader = tier;
  }
}

export const actions = {
  async getNextPayment({ commit }) {
    try {
      const { data } = await getUpcomingInvoice();
      data.date = new Date(data.date);
      commit('SET_NEXT_PAYMENT', data);
    } catch (e) {
      commit('SET_NEXT_PAYMENT', null);
    }
  },

  async getUserInvoices({ commit }) {
    try {
      const { data } = await getInvoices();
      commit('SET_INVOICES', data.docs);
    } catch (e) {
      commit('SET_INVOICES', null);
    }
  },

  setSubscribeTierLoader({ commit }, tier) {
    commit('SET_SUBSCRIBE_TIER_LOADER', tier);
  }
}
