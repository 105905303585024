<template>
  <div class="sidebar-wrapper">
    <nav class="route-links">
      <Search />
      <div v-for="(element, i) in elements" :key="i">
        <nuxt-link :to="element.target" class="route-link">
          {{ element.title }}
        </nuxt-link>

        <div v-if="isActive(element.target)">
          <div
            v-if="isLoadingFinished(element)"
            :class="{ 'children-block': hasChildren(element) }"
          >
            <div
              v-for="(child, j) in getChildrenFor(element)"
              :key="j"
            >
              <a
                :href="child.target.path + child.target.hash"
                :class="{ 'nuxt-link-active': isChildActive(child.target) }"
                class="route-link-nested"
              >
                {{ child.title }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Search from './Search.vue'

export default {
  name: 'AppSidebar',
  components: {
    Search
  },
  props: {
    elements: {
      type: Array,
      required: true
    },
    activeElementPath: {
      type: Array,
      default: () => [null, null]
    }
  },
  methods: {
    isActive (target) {
      return this.$nuxt.$route.path.startsWith(target)
    },
    isChildActive (target) {
      if (target) {
        return this.$nuxt.$route.path === target.path &&
          this.$nuxt.$route.hash === target.hash
      }
      return false
    },
    getChildrenFor (element) {
      return element.getChildren(this.$store.state, this.$store.getters)
    },
    hasChildren (element) {
      return this.getChildrenFor(element).length > 0
    },
    isLoadingFinished (element) {
      if (!element.slug) {
        // this is not a swagger-based element, thus all children are already loaded
        return true
      }
      return this.$store.state.swagger.currentSwaggerSlug === element.slug
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/variables.scss';

.sidebar-wrapper {
  position: sticky;
  top: 40px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  font-size: 17px;
}
@media only screen and (max-width: 768px) {
  .sidebar-wrapper {
    position: initial;
    height: initial;
    align-items: center;
  }
}

.route-links {
  margin-top: 8px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 32px;
}
.route-link {
  display: block;
  text-decoration: none;
  font-weight: 600;
  color: black;
  padding: 5px 0;
  letter-spacing: -0.01em;
  font-size: 17px;
  line-height: 30px;
}

.route-link-nested {
  line-height: 28px;
  display: block;
  text-decoration: none;
  padding: 5px 0 5px 16px;
  transition: color 0.1s ease, border-color 0.1s ease;
  color: black;
  border-left: 1px solid;
  border-color: adjust-color($color-green, $alpha: -0.9);
  font-size: 14px;

  &:hover {
    color: $color-green;
  }
  &.nuxt-link-active {
    color: $color-green;
    border-color: $color-green;
  }
}

.children-block {
  margin-bottom: 8px;
}
</style>
