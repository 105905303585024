<template>
  <div class="i8-button-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'I8ButtonGroup'
}
</script>

<style scoped>
.i8-button-group {
  display: flex;
}
.i8-button-group > * {
  margin-right: 15px;
}
</style>
