import locale1d829464 from '../../translations/loader.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","silentFallbackWarn":true},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","name":"English","domain":"icons8.com","file":"loader.js","href":"https://icons8.com"},{"code":"zh","iso":"zh-CN","name":"简体中文","domain":"igoutu.cn","file":"loader.js","href":"https://igoutu.cn"},{"code":"fr","iso":"fr-FR","name":"Français","domain":"icones8.fr","file":"loader.js","href":"https://icones8.fr"},{"code":"de","iso":"de-DE","name":"Deutsch","domain":"icons8.de","file":"loader.js","href":"https://icons8.de"},{"code":"it","iso":"it-IT","name":"Italiano","domain":"icons8.it","file":"loader.js","href":"https://icons8.it"},{"code":"ja","iso":"ja-JP","name":"日本語","domain":"icons8.jp","file":"loader.js","href":"https://icons8.jp"},{"code":"pt","iso":"pt-BR","name":"Português","domain":"icons8.com.br","file":"loader.js","href":"https://icons8.com.br"},{"code":"ru","iso":"ru-RU","name":"Русский","domain":"icons8.ru","file":"loader.js","href":"https://icons8.ru"},{"code":"es","iso":"es-ES","name":"Español","domain":"iconos8.es","file":"loader.js","href":"https://iconos8.es"},{"code":"ko","iso":"ko-KR","name":"한국어","domain":"icons8.kr","file":"loader.js","href":"https://icons8.kr"},{"code":"zu","iso":"zu-ZA","name":"Zulu","disabled":true,"domain":"icons8.com","file":"loader.js","href":"https://icons8.com"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: true,
  langDir: "./translations/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: true,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true,"syncLocale":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","name":"English","domain":"icons8.com","file":"loader.js","href":"https://icons8.com"},{"code":"zh","iso":"zh-CN","name":"简体中文","domain":"igoutu.cn","file":"loader.js","href":"https://igoutu.cn"},{"code":"fr","iso":"fr-FR","name":"Français","domain":"icones8.fr","file":"loader.js","href":"https://icones8.fr"},{"code":"de","iso":"de-DE","name":"Deutsch","domain":"icons8.de","file":"loader.js","href":"https://icons8.de"},{"code":"it","iso":"it-IT","name":"Italiano","domain":"icons8.it","file":"loader.js","href":"https://icons8.it"},{"code":"ja","iso":"ja-JP","name":"日本語","domain":"icons8.jp","file":"loader.js","href":"https://icons8.jp"},{"code":"pt","iso":"pt-BR","name":"Português","domain":"icons8.com.br","file":"loader.js","href":"https://icons8.com.br"},{"code":"ru","iso":"ru-RU","name":"Русский","domain":"icons8.ru","file":"loader.js","href":"https://icons8.ru"},{"code":"es","iso":"es-ES","name":"Español","domain":"iconos8.es","file":"loader.js","href":"https://iconos8.es"},{"code":"ko","iso":"ko-KR","name":"한국어","domain":"icons8.kr","file":"loader.js","href":"https://icons8.kr"},{"code":"zu","iso":"zu-ZA","name":"Zulu","disabled":true,"domain":"icons8.com","file":"loader.js","href":"https://icons8.com"}],
  localeCodes: ["en","zh","fr","de","it","ja","pt","ru","es","ko","zu"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "l",
  3: "o",
  4: "a",
  5: "d",
  6: "e",
  7: "r",
  8: ".",
  9: "j",
  10: "s",
  11: "\"",
  12: ":",
  13: "\"",
  14: ".",
  15: ".",
  16: "/",
  17: "t",
  18: "r",
  19: "a",
  20: "n",
  21: "s",
  22: "l",
  23: "a",
  24: "t",
  25: "i",
  26: "o",
  27: "n",
  28: "s",
  29: "/",
  30: "l",
  31: "o",
  32: "a",
  33: "d",
  34: "e",
  35: "r",
  36: ".",
  37: "j",
  38: "s",
  39: "\"",
  40: "}",
}

export const localeMessages = {
  'loader.js': () => Promise.resolve(locale1d829464),
}
