export const state = () => ({
  scrollNextTime: true
});

export const mutations = {
  scrollNextTime(state, value) {
    state.scrollNextTime = value
  }
};

export const actions = {
  setScrollNextTime({state, commit}, value) {
    commit('scrollNextTime', value)
  }
};
