<template>
  <div class="search-modal">
    <app-modal
      ref="modal"
      outside-close
      name="search"
      class="search-modal-inner"
      close-button-class="search-modal-close"
      content-class="search-modal"
      @shown="onModalShown"
    >
      <input
        ref="input"
        v-model="text"
        type="text"
        placeholder="Search..."
        class="search-input"
        autofocus
        @input="onInput"
      >
      <divider v-if="results && results.length > 0" />
      <template v-if="results">
        <search-result
          v-for="(result, i) in results.slice(0, 4)"
          :key="i"
          :result="result"
          @click="onSearchResultClick"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppModal from '@icons8/common/src/components/appModal/appModal.vue'
import Divider from '../Divider.vue'
import SearchResult from './SearchResult.vue'

export default {
  name: 'SearchModal',
  components: {
    AppModal,
    Divider,
    SearchResult
  },
  props: {
    initialText: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    text: ''
  }),
  computed: {
    ...mapState({
      results: (state) => state.search.results
    })
  },
  methods: {
    onInput () {
      this.$store.dispatch('search/search', this.text)
    },
    onSearchResultClick () {
      this.$modal.hide('search')
    },
    onModalShown () {
      // modal is attached to <body>
      this.$nextTick(() => document.querySelector('.search-input').focus())
    }
  }
}
</script>

<style lang="scss">
.search-modal-close {
  display: none;
}

.app-modal-container {
  width: 50%;
}

.search-modal {
  /* this selector is less specific than the one inside the modal, so i dunno */
  padding: 0 !important;
  overflow: auto !important;
  top: 100px !important;

  .divider {
    margin-top: 4px;
    margin-bottom: 0;
  }
}
</style>

<style lang="scss" scoped>
@import '~/assets/css/variables.scss';

.search-input {
  border: none;
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  font-weight: 500;
  padding: 16px 24px 16px 42px;
  background: url(/vue-static/search.svg) no-repeat left 16px center / 16px;
  font-family: $font-sans;
}

.search-input-group {
  margin: 24px;
}
</style>
