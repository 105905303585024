import { setSubscription } from "@icons8/common/src/api/billing";
import { getProducts } from "@icons8/common/src/api/products";

export function startTrialFor (alias) {
  return setSubscription({
    addProducts: [alias],
    quantity: 1,
    source: 'developers'
  })
}

export function getApiProducts () {
  return getProducts('api')
}
