<template>
  <div class="default-layout layout-root">
    <I8TopNotification
      v-if="lastPaymentFailed"
      type="danger"
    >
      <PaymentFailed />
    </I8TopNotification>

    <AppHeader />

    <div class="page-margin docs-page-wrapper">
      <SidebarContainer />
      <nuxt class="docs-page-content" />
    </div>

    <I8Footer
      id="app-footer"
      :locale="locale"
      footer-icon="logoIcons"
      footer-name="ICONS8"
    />

    <client-only>
      <app-support />
      <app-notify />
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import I8TopNotification from '@icons8/common/src/components/notifications/I8TopNotification'
import I8Footer from '@icons8/common/src/components/I8Footer'
import AppSupport from '@icons8/common/src/components/appSupport'
import AppHeader from '~/components/common/Header'
import SidebarContainer from '~/components/docs/sidebar/SidebarContainer'
import PaymentFailed from '~/components/common/notifications/PaymentFailed'

export default {
  name: 'DocsLayout',
  components: {
    AppHeader,
    I8Footer,
    I8TopNotification,
    PaymentFailed,
    SidebarContainer,
    AppSupport
  },
  head () {
    return {
      ...this.seo
    }
  },
  computed: {
    ...mapState({
      isGuest: state => state.auth.user.isGuest,
      seo: state => state.seo.data,
      lastPaymentFailed: state => state.auth.user?.activeLicense?.lastPaymentSucceed === false,
      locale: state => state.i18n.locale
    })
  }
}
</script>

<style lang="scss" scoped>
.page-margin {
  margin-top: 140px;
}
.page-wrapper {
  display: flex;
  justify-content: center;
  min-height: 60vh;
}
.page-content {
  flex: 1;
}

@mixin container($width: 1040px) {
  max-width: $width;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

:deep(footer .container) {
  @include container;
  a {
    font-size: 14px;
  }
}

.docs-page-wrapper {
  @include container(1240px);
  display: grid;
  grid-template-columns: 24% 66%;
  grid-column-gap: 120px;
  padding-bottom: 64px;
  padding-left: 40px;
  padding-right: 40px;
}
.docs-page-content {
  margin-right: 64px;
}

@media only screen and (max-width: 768px) {
  .page-wrapper {
    grid-template-columns: 1fr;
  }
}
</style>
