import { getProductFromResource } from '@/utils/products'

/**
 * Get user products of given resource.
 * @param {Resource} resource
 * @param {Array<License>} licenses
 */
export function getProductsForResource (resource, licenses) {
  return licensesToResourceMapper(resource, licenses, findProductsForResource)
}

export function getUserProductsForResource (resource, licenses) {
  return licensesToResourceMapper(resource, licenses, findUserProductsForResource)
}

/**
 * Licenses to resource products mapping helper.
 * @param {Resource} resource
 * @param {Array<License>} licenses
 * @param {function (Array<UserProduct>, Resource): Array<Product>} reducer
 * @return {Array<Product | UserProduct>}
 */
function licensesToResourceMapper (resource, licenses, reducer) {
  if (!resource || !licenses?.length) { return []; }
  return licenses.reduce((acc, license) => {
    const products = reducer(license.products, resource)
    return products.length > 0
      ? [...acc, ...products]
      : acc;
  }, []);
}

/**
 * @param {Array<License>} licenses
 * @return {Array<Product>}
 */
export function getAllProductsFromLicenses (licenses) {
  return licenses.reduce((acc, license) => {
    return [...acc, ...license.products]
  }, [])
}

/**
 * Check if license is trial or not.
 * @param {License} license
 * @return {Boolean}
 */
export function isTrial (license) {
  return license.isTrial;
}

/**
 * Get Resource Products which are in User Products
 * @param {Array<UserProduct>} userProducts
 * @param {Resource} resource
 * @return {Array<Product>}
 */
function findProductsForResource (userProducts, resource) {
  return userProducts.reduce((acc, userProduct) => {
    const product = getProductFromResource(userProduct.alias, resource)
    return product
      ? [...acc, product]
      : acc;
  }, []);
}

/**
 * Get User Products for given Resource
 * @param {Array<UserProduct>} userProducts
 * @param {Resource} resource
 * @return {Array<UserProduct>}
 */
function findUserProductsForResource (userProducts, resource) {
  return userProducts.reduce((acc, userProduct) => {
    const product = getProductFromResource(userProduct.alias, resource)
    return product
      ? [...acc, userProduct]
      : acc;
  }, []);
}
