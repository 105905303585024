'use strict';

function importAll(plugin, r) {
  r.keys().forEach(key => {
    const id = key.split('./').join('').split('.svg').join('');
    const href = r(key).default.id;
    plugin[id] = `<svg width="100%" height="100%"><use href="#${href}"></use></svg>`;
  });
}
export default ((context, inject) => {
  const $icons = {};
  if ([]) {
    const staticSvgs = [];
    staticSvgs.forEach(i => {
      $icons[i.slice(0, -4)] = `<svg width="100%" height="100%"><image width=\"100%\" height=\"100%\" href="/vue-static/developers/svg/${i}"/></svg>`;
    });
  }
  importAll($icons, require.context(`!!svg-sprite-loader!/app/assets/svg`, true, /\.*$/));
  inject('icons', $icons);
});