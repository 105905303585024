<template>
  <header class="header">
    <!-- todo hide lang? -->
    <I8Header :local-links="navLinks" :local-logo="{ title: 'Icons8 Developers' }" variant="product" />
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import I8Header from '@icons8/common/src/components/I8Header'
import { isTrial } from '@/utils/licenses'

const loginProtectedLinks = [
  {
    title: 'API Keys',
    url: '/api-keys',
    dropdown: false,
    spa: true
  }
]

const forPaidOrTrialUser = [
  {
    title: 'Billing',
    url: '/billing',
    dropdown: false,
    spa: true
  },
  {
    title: 'Statistics',
    url: '/statistics',
    dropdown: false,
    spa: true
  }
]

const nonProtectedLinks = [
  {
    title: 'Documentation',
    url: '/docs/getting-started',
    dropdown: false,
    spa: true
  },
  {
    title: 'Status',
    url: '/status',
    dropdown: false,
    spa: true
  },
  {
    title: 'Pricing',
    url: '/pricing',
    dropdown: false,
    spa: true
  },
  {
    title: 'License',
    url: `${process.env.baseUrl}/license`, // crutch: disable spa for license, `spa: false` not working
    dropdown: false,
    spa: false // Not working
  },
  {
    title: 'Forum',
    url: 'https://community.icons8.com/c/api',
    dropdown: false,
    spa: false
  }
]

export default {
  name: 'Header',
  components: {
    I8Header
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user
    }),
    ...mapGetters({
      hasApi: 'hasApi'
    }),
    isLoggedIn () {
      return this.user.loaded && !this.user.isGuest
    },
    hasNonTrialProducts () {
      return this.user?.licenses?.some((el) => !isTrial(el))
    },
    hasTrialProducts () {
      return this.user?.licenses?.some((el) => isTrial(el))
    },
    showPaidUserLinks () {
      return this.hasNonTrialProducts
    },
    showTrialUserLinks () {
      return this.hasTrialProducts
    },
    navLinks () {
      let links = nonProtectedLinks
      if (this.isLoggedIn && this.hasApi) {
        if (this.showPaidUserLinks || this.showTrialUserLinks) {
          links = [...forPaidOrTrialUser, ...links]
        }
        links = [...loginProtectedLinks, ...links]
        return links
      }
      return links
    }
  }
}
</script>
