<template>
  <span
    :class="{ ['color-' + color]: true }"
    class="inline-code"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'InlineCode',
  props: {
    color: {
      type: String,
      default: 'gray'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/variables.scss';

.inline-code {
  font-size: 13px;
  font-family: $font-monospace;
  border-radius: 4px;
  margin: 6px 0;
  padding: 2px 6px;
  border: 1px solid;

  &.color-gray {
    color: black;
    background-color: rgba(black, 0.03);
    border-color: rgba(black, 0.07);
  }

  &.color-green {
    color: $color-green;
    background-color: rgba($color-green, 0.07);
    border-color: rgba($color-green, 0.07);
  }
}
</style>
