<template>
  <app-modal :name="modalName" content-class="change-payment-info-modal">
    <h1 class="change-payment-info-title">Change payment info</h1>

    <form action="" class="change-card-form">
      <div class="card-label">Card info</div>
      <div class="card-input-container">
        <div class="card-primary-info">
          <span v-html="$icons['icons8-bank-card']" class="card-icon"></span>
          <label for="card_number">
            <input
              id="card_number"
              v-model="cardNumber"
              v-mask="'#### #### #### ####'"
              placeholder="Card number"
              type="text"
              class="card-number"
              maxlength="19"
            >
          </label>
        </div>

        <div class="card-secondary-info">
          <label for="card_expire">
            <input
              id="card_expire"
              v-model="cardExpire"
              v-mask="'##/##'"
              placeholder="MM/YY"
              type="text"
              class="card-expire"
            >
          </label>

          <label for="card_cvc">
            <input
              id="card_cvc"
              v-model="cardCVC"
              placeholder="CVC"
              type="text"
              class="card-cvc"
              maxlength="3"
            >
          </label>
        </div>
      </div>

      <I8ButtonGroup>
        <I8Button>
          Save changes
        </I8Button>
        <I8Button secondary>
          Cancel
        </I8Button>
      </I8ButtonGroup>
    </form>
  </app-modal>
</template>

<script>
import { VueMaskDirective } from 'v-mask';
import {I8Button} from '@icons8/vue-kit';
import I8ButtonGroup from '@/components/I8ButtonGroup/index'


// Luhn's algorithm for card validation
function validateCreditCard (cardNo) {
  let s = 0;
  let doubleDigit = false;
  for (let i = cardNo.length - 1; i >= 0; i--) {
    let digit = +cardNo[i];
    if (doubleDigit) {
      digit *= 2;
      if (digit > 9)
        digit -= 9;
    }
    s += digit;
    doubleDigit = !doubleDigit;
  }
  return s % 10 === 0;
}

export default {
  name: "ChangePaymentInfoModal",
  directives: { 'mask': VueMaskDirective },
  components: {
    I8Button,
    I8ButtonGroup
  },
  props: {
    modalName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      cardNumber: '',
      cardExpire: '',
      cardCVC: ''
    }
  },
  methods: {
    validateCreditCard
  }
}
</script>

<style scoped lang="scss">
:deep(.change-payment-info-modal) {
  padding: 40px;
  max-width: 520px;
}

.change-payment-info-title {
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.01em;
  color: #000000;
  margin: 0 0 30px;
}

.card-label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}

.card-input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 16px;
  margin-bottom: 30px;

  input {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    opacity: 0.3;

    border: none;
    padding: 0;
  }
}

.card-primary-info {
  display: flex;
}

.card-secondary-info {
  display: flex;
}

.card-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;

  :deep(svg) {
    width: 24px;
    height: 24px;
  }
}

.card-number {
  margin-right: auto;
}
.card-expire {
  margin-left: auto;
  width: 70px;
}
.card-cvc {
  width: 30px;
}
</style>
