<template>
  <div class="layout-landing">
    <AppHeader />
    <nuxt />

    <I8Footer
      id="app-footer"
      :locale="locale"
      footer-icon="logoIcons"
      footer-name="ICONS8"
    />

    <client-only>
      <app-support />
      <app-notify />
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import I8Footer from '@icons8/common/src/components/I8Footer'
// import ScrollTop from '../components/ScrollTop';
import AppSupport from '@icons8/common/src/components/appSupport'
import AppHeader from '~/components/common/Header'

export default {
  components: {
    AppSupport,
    AppHeader,
    // ScrollTop,
    I8Footer
  },
  head () {
    return {
      ...this.seo
    }
  },
  computed: {
    ...mapState({
      seo: state => state.seo.data,
      locale: state => state.i18n.locale
    })
  }
}
</script>

<style lang="scss">
@media (min-width: 1170px) {
  body div[data-qa="popup-mode-drawer_right"] {
    width: 50vw;
  }
}
</style>
