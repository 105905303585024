<template>
  <div class="payment-failed">
    <i class="card-alert-icon"
       v-html="$icons['icons8-bank-card-alert']"
    />
    <span class="payment-failed-title">
      Your last payment failed
    </span>
    <span class="payment-failed-description">
      Last charge was unsuccessful.
      Please check your payment source as soon as possible
    </span>
    <I8Button
      secondary-white
      class="update-info-button"
      @click.native="openChangeInfoModal"
    >
      Update payment info
    </I8Button>
  </div>
</template>

<script>
import { I8Button } from '@icons8/vue-kit';

import { CHANGE_PAYMENT_INFO_MODAL_NAME } from "~/assets/js/constants";

export default {
  name: "PaymentFailed",
  components: {
    I8Button
  },
  methods: {
    openChangeInfoModal () {
      this.$modal.show(CHANGE_PAYMENT_INFO_MODAL_NAME);
    }
  }
}
</script>

<style scoped>
  .payment-failed {
    display: flex;
    height: 100%;
    letter-spacing: 0.01em;
  }

  .card-alert-icon {
    width: 24px;
  }

  .payment-failed-title {
    margin-left: 11px;
    font-weight: 800;
    font-size: 13px;
    line-height: 44px;
  }

  .payment-failed-description {
    margin-left: 20px;
    font-size: 13px;
    line-height: 44px;
    letter-spacing: 0.01em;
  }

  .update-info-button {
    margin-left: auto;
  }
</style>
