import axios from 'axios'

const STATUS = {
  1: 'down',
  2: 'ok',
  8: 'seems_down',
  9: 'down'
};

export const state = () => ({
  statuses: {},
  loading: false,
  error: false
});

export const mutations = {
  status(state, { id, log, status, latency }) {
    state.statuses = {
      ...state.statuses,
      [id]: {
        log,
        status,
        latency
      }
    }
  },
  SET_LOADING(state, val) {
    state.loading = val;
  },
  SET_ERROR(state, val) {
    state.error = val;
  }
};

export const actions = {
  async load ({ commit }) {
    commit('SET_LOADING', true)

    try {
      const { data } = await axios.get(
        `${process.env.avenueApiUrl}/_avenue_services_/developers/uptimerobot/monitors`
      );

      // eslint-disable-next-line camelcase
      for (const { id, logs, response_times, status } of data.monitors) {
        const log = logs.map(x => ({
          datetime: x.datetime, status: STATUS[x.type]
        }));
        const latency = {};
        // eslint-disable-next-line camelcase
        for (const { datetime, value } of response_times) {
          latency[Number(datetime) * 1000] = Number(value);
        }
        commit('status', { id, log, status, latency });
        commit('SET_ERROR', false);
      }
    } catch (e) {
      commit('SET_ERROR', true);
    }

    commit('SET_LOADING', false);
  }
};

export const getters = {
  getStatus: state => id => {
    if (state.error) {
      return 'seems_down';
    }
    return state.statuses[id]
      ? STATUS[state.statuses[id].status]
      : null
  }
};
