import { curry } from '@/utils/index'

/**
 * Return resource which contains given product.
 * @param {Product | UserProduct} product
 * @param {Array<Resource>} resources
 * @return {Resource}
 */
export function getResourceWithProduct (product, resources) {
  return resources.find(resource => getProductFromResource(product.alias, resource))
}

/**
 * @param {string} alias
 * @param {Resource} resource
 * @return {Product}
 */
export function getProductFromResource (alias, resource) {
  if (resource.month && resource.year) {
    return getProductFromList(alias, resource.month) ||
      getProductFromList(alias, resource.year)
  }
}

/**
 * Get product with given alias in given list of products
 * @param {string} alias
 * @param {Array<Product>} products
 * @return {Product}
 */
export function getProductFromList (alias, products) {
  return products.find(product => product.alias === alias)
}

/**
 * @param {Product | UserProduct} left
 * @param {Product | UserProduct} right
 * @return Boolean
 */
function greaterThan (left, right) {
  if (left.isTrial) { return false } // trial cannot be larger than anything
  if (!left.isTrial && right.isTrial) { return true } // non-trial is always larger than trial
  return left?.amount.value > right?.amount.value ||
    left?.price > right?.price
}
export const gt = curry(greaterThan)

/**
 * @param {Product | UserProduct} left
 * @param {Product | UserProduct} right
 * @return Boolean
 */
function lessThan (left, right) {
  if (right.isTrial) { return false } // cannot be less than trial
  if (left.isTrial && !right.isTrial) { return true } // trial is always less than non-trial
  return left?.amount.value < right?.amount.value ||
    left?.price < right?.price
}
export const lt = curry(lessThan)

/**
 * @param {Product | UserProduct} left
 * @param {Product | UserProduct} right
 * @return Boolean
 */
function equal (left, right) {
  return left.alias === right.alias
}
export const eq = curry(equal)

/**
 * Return list of given resource products.
 * @param {Resource} resource
 * @return {Array<Product>}
 */
export function flattenResourceProducts (resource) {
  return [
    ...resource.month,
    ...resource.year
  ]
}
