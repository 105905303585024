<template>
  <app-modal v-if="expirationDate" :name="modalName" content-class="cancel-sub-modal">
    <h1 class="cancel-sub-title">
      Why are you leaving?
    </h1>

    <p class="cancel-sub-description">
      Cancellation will be effective at the end of your current billing period on
      <b>{{ expirationDate }}</b>. We will appreciate it,
      if you could tell reasons why you leave us.
    </p>

    <form action="" class="cancel-sub-form">
      <ul class="reasons-list">
        <li
          v-for="(choice, idx) in reasons"
          :key="idx"
          class="reasons-list__item"
          @click="changeReason(idx)"
        >
          <label
            :for="`reason-${idx}`"
            class="reasons-list__item-content"
          >
            {{ choice.text }}
            <input :id="`reason-${idx}`" type="radio" name="reason">
            <span class="checkmark" />
          </label>
        </li>
      </ul>

      <div v-if="showTextarea" class="comment-container">
        <label for="comment" class="comment-label">
          Your comment
        </label>

        <textarea
          id="comment"
          v-model="comment"
          :placeholder="textareaPlaceholder"
          name="comment"
        />
      </div>

      <I8ButtonGroup>
        <I8Button
          danger
          class="button unsubscribe-button"
          @click.prevent="handleUnsubscribe"
        >
          <I8Loader v-if="isLoading" color="#000000" />
          <span v-else>Unsubscribe</span>
        </I8Button>
        <I8Button
          secondary
          class="button"
          @click.prevent="handleCancel"
        >
          Cancel
        </I8Button>
      </I8ButtonGroup>
    </form>
  </app-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { I8Button, I8Loader } from '@icons8/vue-kit'

import { unsubscribe } from '@icons8/common/src/api/billing'
import dayjs from 'dayjs'
import I8ButtonGroup from '@/components/I8ButtonGroup/index'

export default {
  name: 'CancelSubscriptionModal',
  components: {
    I8ButtonGroup,
    I8Button,
    I8Loader
  },
  props: {
    modalName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      reasons: [
        { slug: 'notNeedNow', text: 'I don\'t need it at the moment' },
        { slug: 'tooExpensive', text: 'It\'s too expensive' },
        { slug: 'badQuality', text: 'I\'m not happy with the quality' },
        { slug: 'missingFeatures', text: 'Some features are missing' },
        { slug: 'other', text: 'Other' }
      ],
      reasonIdx: 0,
      comment: '',
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    license () {
      if (!this.user.licenses) return null

      const licenses = this.user.licenses.filter(license => license.type === 'api')
      return licenses && licenses.length ? licenses[0] : null
    },
    expirationDate () {
      if (!this.license) return ''

      const timestamp = this.license.expireAt
      return timestamp ? dayjs(+timestamp).format('MMMM D, YYYY') : ''
    },
    showTextarea () {
      return !(this.reasonIdx === 0 || this.reasonIdx === 1)
    },
    textareaPlaceholder () {
      switch (this.reasonIdx) {
        case 2:
          return 'What would you like us to improve?'
        case 3:
          return 'Have your suggestions heard!'
        case 4:
          return 'What\'s bothering you?'
        default:
          return ''
      }
    }
  },
  methods: {
    ...mapActions({
      getUserInfo: 'fillUserInfo'
    }),
    changeReason (idx) {
      this.reasonIdx = idx
    },
    async handleUnsubscribe () {
      this.isLoading = true
      try {
        await unsubscribe({
          licenseId: this.license?.id,
          reason: this.reasons[this.reasonIdx].slug,
          notes: this.comment
        })
        this.getUserInfo()
      } catch (e) {
        this.$error({
          text: 'Unsubscribe failed'
        })
      } finally {
        this.isLoading = false
        this.$modal.hide(this.modalName)
      }
    },
    handleCancel () {
      this.$modal.hide(this.modalName)
    }
  }
}
</script>

<style scoped lang="scss">
  :deep(.cancel-sub-modal) {
    padding: 40px;
  }

  .cancel-sub-title {
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #000000;
    margin: 0 0 20px;
  }

  .cancel-sub-description {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #000000;
    margin-bottom: 30px;
  }

  .reasons-list {
    list-style: none;
    padding: 0;
    margin: 0 0 30px 0;
  }

  .reasons-list__item {
    background: #F8F8F8;
    border-radius: 4px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .reasons-list__item-content {
    box-sizing: border-box;
    padding: 13px 20px 13px 42px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .checkmark:after {
        display: block;
      }

      &:checked ~ .checkmark {
        border: 1px solid #28B351;
      }
    }

    .checkmark {
      position: absolute;
      top: 15px;
      left: 15px;
      height: 14px;
      width: 14px;
      margin: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      box-sizing: border-box;

      &:after {
        content: "";
        position: absolute;
        display: none;

        top: 3px;
        left: 3px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #28B351;
      }
    }

    &:hover input ~ .checkmark {
      border: 1px solid #28B351;
    }
  }

  .comment-label {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
  }

  textarea {
    width: 100%;
    padding: 11px 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    resize: vertical;
  }

  .comment-container {
    margin-bottom: 30px;
  }

  .unsubscribe-button {
    min-width: 140px;
    min-height: 44px;
  }

  .button {
    height: 44px;
    width: 144px;
  }
</style>
