import Vue from 'vue'
import appNotify from '@icons8/common/src/components/appNotify'
import appModal from '@icons8/common/src/components/appModal'


// I have no idea why, but it is needed
import '@icons8/common/src/assets/css/common.scss'

const isVue2 = true // will come from bridge

export default (ctx) => {
  /** @type {import('vue')['default']} */
  const vue = isVue2 ? Vue : ctx.vueApp

  /* Components */
  vue.use(appNotify)
  vue.use(appModal)
}
