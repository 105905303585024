import Vue from 'vue';
import Vuex from 'vuex';
import Meta from 'vue-meta';
import ClientOnly from 'vue-client-only';
import NoSsr from 'vue-no-ssr';
import { createRouter } from './router.js';
import NuxtChild from './components/nuxt-child.js';
import NuxtError from '../layouts/error.vue';
import Nuxt from './components/nuxt.js';
import App from './App.js';
import { setContext, getLocation, getRouteData, normalizeError } from './utils';
import { createStore } from './store.js';

/* Plugins */

import nuxt_plugin_appplugin_6e4ae16c from 'nuxt_plugin_appplugin_6e4ae16c'; // Source: ../node_modules/@nuxt/bridge/dist/runtime/app.plugin.mjs (mode: 'all')
import nuxt_plugin_configplugin_4417b6a3 from 'nuxt_plugin_configplugin_4417b6a3'; // Source: ../node_modules/@nuxt/bridge/dist/runtime/config.plugin.mjs (mode: 'all')
import nuxt_plugin_nitrobridgeserver_1ab611b4 from 'nuxt_plugin_nitrobridgeserver_1ab611b4'; // Source: ./nitro-bridge.server.mjs (mode: 'server')
import nuxt_plugin_nitrobridgeclient_6d7d5b88 from 'nuxt_plugin_nitrobridgeclient_6d7d5b88'; // Source: ./nitro-bridge.client.mjs (mode: 'client')
import nuxt_plugin_tapfiliateclient_2542579c from 'nuxt_plugin_tapfiliateclient_2542579c'; // Source: ./tapfiliate.client.js (mode: 'client')
import nuxt_plugin_icons_9c1d6dbc from 'nuxt_plugin_icons_9c1d6dbc'; // Source: ./icons.js (mode: 'all')
import nuxt_plugin_googleanalytics_0f318e20 from 'nuxt_plugin_googleanalytics_0f318e20'; // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_pluginutils_ea6a7e96 from 'nuxt_plugin_pluginutils_ea6a7e96'; // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_8dc41680 from 'nuxt_plugin_pluginrouting_8dc41680'; // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_7e05a2a2 from 'nuxt_plugin_pluginmain_7e05a2a2'; // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_capiplugin_2b56d48d from 'nuxt_plugin_capiplugin_2b56d48d'; // Source: ./capi.plugin.mjs (mode: 'all')
import nuxt_plugin_errorpluginserver_e4c11288 from 'nuxt_plugin_errorpluginserver_e4c11288'; // Source: ../node_modules/@nuxt/bridge/dist/runtime/error.plugin.server.mjs (mode: 'server')
import nuxt_plugin_highlightclient_4965925c from 'nuxt_plugin_highlightclient_4965925c'; // Source: ../plugins/highlight.client.js (mode: 'client')
import nuxt_plugin_typeform_60d94103 from 'nuxt_plugin_typeform_60d94103'; // Source: ../plugins/typeform.js (mode: 'all')
import nuxt_plugin_icons8common_171c0bc3 from 'nuxt_plugin_icons8common_171c0bc3'; // Source: ../plugins/icons8-common (mode: 'all')
import nuxt_plugin_analytics_3a2f3ec0 from 'nuxt_plugin_analytics_3a2f3ec0'; // Source: ../node_modules/@icons8/common/src/plugins/analytics.js (mode: 'all')
import nuxt_plugin_analyticsv2client_482b9050 from 'nuxt_plugin_analyticsv2client_482b9050'; // Source: ../node_modules/@icons8/common/src/plugins/analytics_v2.client.ts (mode: 'client')
import nuxt_plugin_build_2243bc38 from 'nuxt_plugin_build_2243bc38'; // Source: ../node_modules/@icons8/common/src/plugins/build.js (mode: 'all')
import nuxt_plugin_i18nFormatter_0359d2cc from 'nuxt_plugin_i18nFormatter_0359d2cc'; // Source: ../node_modules/@icons8/common/src/plugins/i18nFormatter.js (mode: 'all')
import nuxt_plugin_i18n_1db2f1ca from 'nuxt_plugin_i18n_1db2f1ca'; // Source: ../node_modules/@icons8/common/src/plugins/i18n.js (mode: 'all')
import nuxt_plugin_authv3_4a6488b2 from 'nuxt_plugin_authv3_4a6488b2'; // Source: ../node_modules/@icons8/common/src/plugins/auth_v3.js (mode: 'all')
import nuxt_plugin_seoWithHreflang_14f9a1aa from 'nuxt_plugin_seoWithHreflang_14f9a1aa'; // Source: ../node_modules/@icons8/common/src/plugins/seoWithHreflang.js (mode: 'all')
import nuxt_plugin_search_387a0c80 from 'nuxt_plugin_search_387a0c80'; // Source: ../node_modules/@icons8/common/src/plugins/search.js (mode: 'all')
import nuxt_plugin_saveRef_0783cd74 from 'nuxt_plugin_saveRef_0783cd74'; // Source: ../node_modules/@icons8/common/src/plugins/saveRef.ts (mode: 'all')
import nuxt_plugin_commonIcons_69e0c3d7 from 'nuxt_plugin_commonIcons_69e0c3d7'; // Source: ../node_modules/@icons8/common/src/plugins/commonIcons.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly);

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render(h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true;
      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead');
    }
    return NoSsr.render(h, ctx);
  }
});

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild);
Vue.component('NChild', NuxtChild);

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt);
Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null;
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt;
    }
    return globalNuxt;
  },
  configurable: true
});
Vue.use(Meta, {
  "keyName": "head",
  "attribute": "data-n-head",
  "ssrAttribute": "data-n-head-ssr",
  "tagIDKeyName": "hid"
});
const defaultTransition = {
  "name": "page",
  "mode": "out-in",
  "appear": false,
  "appearClass": "appear",
  "appearActiveClass": "appear-active",
  "appearToClass": "appear-to"
};
const originalRegisterModule = Vuex.Store.prototype.registerModule;
function registerModule(path, rawModule, options) {
  if (options === void 0) {
    options = {};
  }
  const preserveState = process.client && (Array.isArray(path) ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state) : path in this.state);
  return originalRegisterModule.call(this, path, rawModule, {
    preserveState,
    ...options
  });
}
async function createApp(ssrContext, config) {
  if (config === void 0) {
    config = {};
  }
  const store = createStore(ssrContext);
  const router = await createRouter(ssrContext, config, {
    store
  });

  // Add this.$router into store actions/mutations
  store.$router = router;

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule;

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {
      "title": "Download free icons, music, stock photos, vectors",
      "meta": [{
        "charset": "utf-8"
      }, {
        "name": "viewport",
        "content": "width=device-width, initial-scale=1"
      }, {
        "name": "format-detection",
        "content": "telephone=no"
      }, {
        "name": "robots",
        "hid": "robots",
        "content": "noodp"
      }, {
        "http-equiv": "X-UA-Compatible",
        "content": "IE=edge, chrome=1"
      }, {
        "name": "description",
        "hid": "description",
        "content": "Designers, download the design stuff for free — icons, photos, UX illustrations, and music for your videos."
      }],
      "link": [{
        "rel": "icon",
        "type": "image\u002Fpng",
        "hid": "favicon",
        "href": "\u002Fvue-static\u002Ffavicon.png"
      }, {
        "rel": "apple-touch-icon",
        "href": "\u002Fvue-static\u002Fwebclip.png"
      }],
      "script": [],
      "style": []
    },
    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions(transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions];
        }
        transitions = transitions.map(transition => {
          if (!transition) {
            transition = defaultTransition;
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, {
              name: transition
            });
          } else {
            transition = Object.assign({}, defaultTransition, transition);
          }
          return transition;
        });
        this.$options.nuxt.transitions = transitions;
        return transitions;
      },
      err: null,
      dateErr: null,
      error(err) {
        err = err || null;
        app.context._errored = Boolean(err);
        err = err ? normalizeError(err) : null;
        let nuxt = app.nuxt; // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt;
        }
        nuxt.dateErr = Date.now();
        nuxt.err = err;
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err;
        }
        return err;
      }
    },
    ...App
  };

  // Make app available into store via this.app
  store.app = app;
  const next = ssrContext ? ssrContext.next : location => app.router.push(location);
  // Resolve route
  let route;
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route;
  } else {
    const path = getLocation(router.options.base, router.options.mode);
    route = router.resolve(path).route;
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  });
  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided');
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`);
    }
    key = '$' + key;
    // Add into app
    app[key] = value;
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value;
    }

    // Add into store
    store[key] = app[key];

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__';
    if (Vue[installKey]) {
      return;
    }
    Vue[installKey] = true;
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get() {
            return this.$root.$options[key];
          }
        });
      }
    });
  }

  // Inject runtime config as $config
  inject('config', config);
  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state);
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData) {
      if (previewData === void 0) {
        previewData = {};
      }
      app.previewData = Object.assign({}, previewData);
      inject('preview', previewData);
    };
  }
  // Plugin execution

  if (typeof nuxt_plugin_appplugin_6e4ae16c === 'function') {
    await nuxt_plugin_appplugin_6e4ae16c(app.context, inject);
  }
  if (typeof nuxt_plugin_configplugin_4417b6a3 === 'function') {
    await nuxt_plugin_configplugin_4417b6a3(app.context, inject);
  }
  if (process.server && typeof nuxt_plugin_nitrobridgeserver_1ab611b4 === 'function') {
    await nuxt_plugin_nitrobridgeserver_1ab611b4(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_nitrobridgeclient_6d7d5b88 === 'function') {
    await nuxt_plugin_nitrobridgeclient_6d7d5b88(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_tapfiliateclient_2542579c === 'function') {
    await nuxt_plugin_tapfiliateclient_2542579c(app.context, inject);
  }
  if (typeof nuxt_plugin_icons_9c1d6dbc === 'function') {
    await nuxt_plugin_icons_9c1d6dbc(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_googleanalytics_0f318e20 === 'function') {
    await nuxt_plugin_googleanalytics_0f318e20(app.context, inject);
  }
  if (typeof nuxt_plugin_pluginutils_ea6a7e96 === 'function') {
    await nuxt_plugin_pluginutils_ea6a7e96(app.context, inject);
  }
  if (typeof nuxt_plugin_pluginrouting_8dc41680 === 'function') {
    await nuxt_plugin_pluginrouting_8dc41680(app.context, inject);
  }
  if (typeof nuxt_plugin_pluginmain_7e05a2a2 === 'function') {
    await nuxt_plugin_pluginmain_7e05a2a2(app.context, inject);
  }
  if (typeof nuxt_plugin_capiplugin_2b56d48d === 'function') {
    await nuxt_plugin_capiplugin_2b56d48d(app.context, inject);
  }
  if (process.server && typeof nuxt_plugin_errorpluginserver_e4c11288 === 'function') {
    await nuxt_plugin_errorpluginserver_e4c11288(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_highlightclient_4965925c === 'function') {
    await nuxt_plugin_highlightclient_4965925c(app.context, inject);
  }
  if (typeof nuxt_plugin_typeform_60d94103 === 'function') {
    await nuxt_plugin_typeform_60d94103(app.context, inject);
  }
  if (typeof nuxt_plugin_icons8common_171c0bc3 === 'function') {
    await nuxt_plugin_icons8common_171c0bc3(app.context, inject);
  }
  if (typeof nuxt_plugin_analytics_3a2f3ec0 === 'function') {
    await nuxt_plugin_analytics_3a2f3ec0(app.context, inject);
  }
  if (process.client && typeof nuxt_plugin_analyticsv2client_482b9050 === 'function') {
    await nuxt_plugin_analyticsv2client_482b9050(app.context, inject);
  }
  if (typeof nuxt_plugin_build_2243bc38 === 'function') {
    await nuxt_plugin_build_2243bc38(app.context, inject);
  }
  if (typeof nuxt_plugin_i18nFormatter_0359d2cc === 'function') {
    await nuxt_plugin_i18nFormatter_0359d2cc(app.context, inject);
  }
  if (typeof nuxt_plugin_i18n_1db2f1ca === 'function') {
    await nuxt_plugin_i18n_1db2f1ca(app.context, inject);
  }
  if (typeof nuxt_plugin_authv3_4a6488b2 === 'function') {
    await nuxt_plugin_authv3_4a6488b2(app.context, inject);
  }
  if (typeof nuxt_plugin_seoWithHreflang_14f9a1aa === 'function') {
    await nuxt_plugin_seoWithHreflang_14f9a1aa(app.context, inject);
  }
  if (typeof nuxt_plugin_search_387a0c80 === 'function') {
    await nuxt_plugin_search_387a0c80(app.context, inject);
  }
  if (typeof nuxt_plugin_saveRef_0783cd74 === 'function') {
    await nuxt_plugin_saveRef_0783cd74(app.context, inject);
  }
  if (typeof nuxt_plugin_commonIcons_69e0c3d7 === 'function') {
    await nuxt_plugin_commonIcons_69e0c3d7(app.context, inject);
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.');
    };
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const {
        route
      } = router.resolve(app.context.route.fullPath);
      if (!route.matched.length) {
        return resolve();
      }
    }
    router.replace(app.context.route.fullPath, resolve, err => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err);
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve();

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath;
        }
        app.context.route = await getRouteData(to);
        app.context.params = to.params || {};
        app.context.query = to.query || {};
        unregister();
        resolve();
      });
    });
  });
  return {
    store,
    app,
    router
  };
}
export { createApp, NuxtError };