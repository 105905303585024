import axios from 'axios';
import { createSlider } from '@typeform/embed';
import '@typeform/embed/build/css/slider.css';
const TYPEFORM_GET_RESULT_URL = `${process.env.typeformBaseUrl}/forms/${process.env.typeformId}/responses`;
function openTypeform(callback) {
  createSlider(process.env.typeformId, {
    autoClose: 10000,
    hideHeaders: true,
    hideFooter: true,
    onSubmit: callback
  }).open();
}
function getTypeformResult(id) {
  return axios.get(TYPEFORM_GET_RESULT_URL, {
    headers: {
      Authorization: `Bearer ${process.env.typeformToken}`
    },
    params: {
      included_response_ids: id
    }
  });
}
export default ((_ref, inject) => {
  let {
    app
  } = _ref;
  inject('openTypeform', openTypeform);
  inject('getTypeformResult', getTypeformResult);
});