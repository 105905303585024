export const CANCEL_SUBSCRIPTION_MODAL_NAME = 'cancel-sub-modal'
export const CHANGE_PAYMENT_INFO_MODAL_NAME = 'change-card-modal'
export const UPGRADE_API_MODAL_NAME = 'upgradeAPIModal'
export const CONFIRM_UPGRADE_MODAL_NAME = 'confirmUpgradeModal'
export const SUCCESSFUL_SUBSCRIPTION_MODAL_NAME = 'successfulSubscriptionModal'
export const UPGRADE_TO_YEAR_MODAL_NAME = 'upgradeToYearModal'
export const CONFIRM_RESET_KEY_MODAL_NAME = 'confirmResetKeyModal'

export const productsMeta = [
  { slug: 'icons', name: 'Icons' },
  { slug: 'photos', name: 'Photos' },
  { slug: 'vectors', name: 'Vector' },
  { slug: 'music', name: 'Music' },
  { slug: 'upscaler', name: 'Upscaler' },
  { slug: 'bgRemover', name: 'Background Remover' },
  { slug: 'faceSwapper', name: 'Face Swapper' }
]

export const tierColors = {
  trial: {
    backgroundColor: '#FDF9F1',
    color: '#E5AB3B'
  },
  startup: {
    backgroundColor: '#EFFAF2',
    color: '#1FB141'
  },
  growth: {
    backgroundColor: '#EFF8FD',
    color: '#179DE3'
  },
  enterprise: {
    backgroundColor: '#F3F1FA',
    color: '#553EB5'
  }
}
