<template>
  <section class="error-page">
    <span v-html="$icons['icons8-developers-error-emoji']" />
    <h2 class="title">
      {{ titleText }}
    </h2>
    <p class="description">
      Please, check your internet connection and try again.
      If it didn't help, contact us.
    </p>
  </section>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  computed: {
    titleText () {
      if (this.error.statusCode === 404) {
        return 'Page not found'
      }
      if (Math.floor(this.error.statusCode / 100) === 5) {
        return 'Something went wrong'
      }
      return this.error.message
    }
  }
}
</script>

<style scoped>
  .error-page {
    max-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }

  .description {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #000000;
  }
</style>
