export default function (to, from, _savedPosition) {
  if (this.app.$store.state.anchorHack.scrollNextTime === false) {
    this.app.$store.commit('anchorHack/scrollNextTime', true);
    if (to.path === from.path) {
      return undefined
    }
  }
  if (to.hash) {
    // this fires too early, so if the element is there, then take care of it
    // otherwise, target pages component should take care of it
    if (document.querySelector(to.hash)) {
      return { selector: to.hash }
    }
  }
  return { x: 0, y: 0 }
}
