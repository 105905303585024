<template>
  <div class="search-result">
    <nuxt-link :to="link" @click.native="onClick">
      <div class="search-result-content">
        <h3 class="search-result-header">
          <span class="api-type-addon">
            {{ result.apiTypeTitle }} &mdash;
          </span>
          {{ result.summary }}
        </h3>
        <inline-code class="search-result-path">
          {{ prettyMethod }} {{ result.pathName }}
        </inline-code>
<!--        <markdown-->
<!--          :content="result.description || ''"-->
<!--          class="search-result-description"-->
<!--        />-->
      </div>
    </nuxt-link>
  </div>
</template>

<script>
import InlineCode from '../InlineCode.vue'
// import Markdown from '../Markdown.vue'
import { getPathId } from '~/utils'

export default {
  name: 'SearchResult',
  components: {
    InlineCode
    // Markdown
  },
  props: {
    result: {
      type: Object,
      required: true
    }
  },
  computed: {
    prettyMethod () {
      return this.result.method.toUpperCase()
    },
    pathId () {
      return getPathId(this.result.method, this.result.pathName)
    },
    link () {
      return '/docs/' + this.result.slug + '#' + this.pathId
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/css/variables.scss';
@import '~/assets/css/mixins.scss';

.search-result {
  font-size: 14px;
  font-family: $font-sans;

  a {
    text-decoration: none;
    color: black;
    display: block;

    // this is moderately funny
    // hover would not work properly otherwise
    padding-top: 0.1px;
    padding-bottom: 0.1px;
  }

  .divider {
    margin: 0;
  }

  h3 {
    font-size: 17px;
    font-weight: 600;

    .api-type-addon {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .search-result-content {
    padding: 0 32px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
}

</style>
