import { isTrial } from '@/utils/licenses'
import { swaggers } from '@/store/swagger'
import { debounce, getPlanFromProduct } from '@/utils'

export const state = () => ({})

export const actions = {
  async nuxtServerInit ({ dispatch }, { res, error }) {
    try {
      await Promise.all([
        await Promise.all(Object.keys(swaggers).map(async swag => {
          await dispatch('swagger/preload', swag)
        })),
        await dispatch('products/getAllProducts')
      ])
    } catch (e) {
      error({ statusCode: res.statusCode, message: 'Something went wrong' })
    }
  },

  /**
   * Fetch user subscription after creating or updating subscription.
   * Makes recursive call if subscription is not finalized.
   * @param dispatch
   * @param rootState
   * @return {Promise<object>}
   */
  async fetchUserSubscription ({ dispatch, rootState }) {
    try {
      await dispatch('setLicenses')
      const licenses = rootState.auth.user?.licenses
      if (licenses?.some(el => !el.isSetupFinished)) {
        await debounce(async () => {
          await dispatch('fetchUserSubscription')
        }, 300)()
      }
    } catch (e) {
      throw new Error(e)
    }
  }
}

export const getters = {
  hasApi: (rootState) => {
    if (!rootState.auth.user?.activeLicense) { return false }

    const { iconsApi, photosApi, soundsApi, illustrationsApi, upscalerApi, bgRemoverApi } = rootState.auth.user?.activeLicense
    return iconsApi || photosApi || soundsApi || illustrationsApi || upscalerApi || bgRemoverApi
  },

  hasOnlyTrial: (rootState) => {
    const licenses = rootState.auth.user?.licenses
    return licenses.every(isTrial)
  },

  getExactProductFromLicenses: (rootState) => (planAlias) => {
    if (!rootState.auth.user?.licenses) { return null }
    return rootState.auth.user.licenses.find(license => {
      return license.products.find(product => {
        return product.alias === planAlias
      })
    })
  },

  hasLicenseOfThisPlanAlias: (rootState, rootGetters) => (planAlias) => {
    const licenses = rootState.auth.user?.licenses
    if (!licenses) { return false }
    const productWithPlan = rootGetters['products/getResourceByProductAlias'](planAlias)
    return licenses.find(license => {
      return license.products.find(el => {
        return getPlanFromProduct(productWithPlan)(el.alias)
      })
    })
  }
}

const fetchUserLicensesAfterLogin = (store) => {
  store.subscribe(async (mutation) => {
    if (
      mutation.type === 'USER_FILL_INFO' ||
      mutation.type === 'USER_LOGGED_IN'
    ) {
      await store.dispatch('setLicenses')
    }
  })
}

export const plugins = [fetchUserLicensesAfterLogin]
