import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _34f2ea31 = () => interopDefault(import('../pages/api-keys.vue' /* webpackChunkName: "pages/api-keys" */))
const _e0205ffe = () => interopDefault(import('../pages/billing.vue' /* webpackChunkName: "pages/billing" */))
const _63cfc71a = () => interopDefault(import('../pages/changelog.vue' /* webpackChunkName: "pages/changelog" */))
const _3e43e730 = () => interopDefault(import('../pages/docs/index.vue' /* webpackChunkName: "pages/docs/index" */))
const _6439dec7 = () => interopDefault(import('../pages/license.vue' /* webpackChunkName: "pages/license" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _b3f82848 = () => interopDefault(import('../pages/order-processing.vue' /* webpackChunkName: "pages/order-processing" */))
const _3162a068 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _3b5929e6 = () => interopDefault(import('../pages/statistics.vue' /* webpackChunkName: "pages/statistics" */))
const _7647c35c = () => interopDefault(import('../pages/status.vue' /* webpackChunkName: "pages/status" */))
const _52df3be4 = () => interopDefault(import('../pages/docs/getting-started.vue' /* webpackChunkName: "pages/docs/getting-started" */))
const _41b129c0 = () => interopDefault(import('../pages/docs/_slug.vue' /* webpackChunkName: "pages/docs/_slug" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/docs",
    redirect: {"name":"docs-getting-started"}
  }, {
    path: "/api-keys",
    component: _34f2ea31,
    name: "api-keys"
  }, {
    path: "/billing",
    component: _e0205ffe,
    name: "billing"
  }, {
    path: "/changelog",
    component: _63cfc71a,
    name: "changelog"
  }, {
    path: "/docs",
    component: _3e43e730,
    name: "docs"
  }, {
    path: "/license",
    component: _6439dec7,
    name: "license"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/order-processing",
    component: _b3f82848,
    name: "order-processing"
  }, {
    path: "/pricing",
    component: _3162a068,
    name: "pricing"
  }, {
    path: "/search",
    component: _3d268e12,
    name: "search"
  }, {
    path: "/statistics",
    component: _3b5929e6,
    name: "statistics"
  }, {
    path: "/status",
    component: _7647c35c,
    name: "status"
  }, {
    path: "/docs/getting-started",
    component: _52df3be4,
    name: "docs-getting-started"
  }, {
    path: "/docs/:slug",
    component: _41b129c0,
    name: "docs-slug"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
