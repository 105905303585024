<template>
  <div class="default-layout layout-root">
    <I8TopNotification
      v-if="lastPaymentFailed"
      type="danger"
    >
      <PaymentFailed />
    </I8TopNotification>

    <AppHeader />

    <div class="page-margin page-wrapper">
      <nuxt class="page-content" />
    </div>
    <I8Footer
      id="app-footer"
      :locale="locale"
      footer-icon="logoIcons"
      footer-name="ICONS8"
    />

    <ChangePaymentInfoModal v-if="!isGuest" :modal-name="changePaymentInfoModalName" />
    <CancelSubscriptionModal v-if="!isGuest" :modal-name="cancelSubModalName" />
    <!-- <UpgradeAPIModal v-if="!isGuest" :modal-name="upgradeApiModalName" /> -->

    <client-only>
      <app-support />
      <app-notify />
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import I8TopNotification from '@icons8/common/src/components/notifications/I8TopNotification'
import I8Footer from '@icons8/common/src/components/I8Footer'
import AppSupport from '@icons8/common/src/components/appSupport'

import AppHeader from '~/components/common/Header'
import PaymentFailed from '~/components/common/notifications/PaymentFailed'
import ChangePaymentInfoModal from '~/components/common/ChangePaymentInfoModal'
import CancelSubscriptionModal from '~/components/common/CancelSubscriptionModal'

import {
  CANCEL_SUBSCRIPTION_MODAL_NAME,
  CHANGE_PAYMENT_INFO_MODAL_NAME,
  UPGRADE_API_MODAL_NAME
} from '~/assets/js/constants'
// import UpgradeAPIModal from '~/components/common/upgradeApiModal/UpgradeAPIModal'

export default {
  name: 'Default',
  components: {
    AppHeader,
    I8TopNotification,
    // UpgradeAPIModal,
    PaymentFailed,
    ChangePaymentInfoModal,
    CancelSubscriptionModal,
    I8Footer,
    AppSupport
  },
  data () {
    return {
      cancelSubModalName: CANCEL_SUBSCRIPTION_MODAL_NAME,
      changePaymentInfoModalName: CHANGE_PAYMENT_INFO_MODAL_NAME,
      upgradeApiModalName: UPGRADE_API_MODAL_NAME
    }
  },
  head () {
    return {
      ...this.seo
    }
  },
  computed: {
    ...mapState({
      isGuest: state => state.auth.user.isGuest,
      seo: state => state.seo.data,
      lastPaymentFailed: state => state.auth.user?.activeLicense?.lastPaymentSucceed === false,
      locale: state => state.i18n.locale
    })
  }
}
</script>

<style lang="scss" scoped>
.page-margin {
  margin-top: 140px;
}
.page-wrapper {
  display: flex;
  justify-content: center;
  min-height: 60vh;
}
.page-content {
  flex: 1;
}

@media only screen and (max-width: 768px) {
  .page-wrapper {
    grid-template-columns: 1fr;
  }
}
</style>
