import { authenticatedAxios } from '~/utils/authenticatedAxios'

export const state = () => ({
  stats: {},
  apiKeys: {}
});

export const mutations = {
  stats(state, newStats) {
    state.stats = newStats
  },
  apiKeys(state, newApiKeys) {
    state.apiKeys = newApiKeys
  }
};

export const actions = {
  async loadStats({ commit }, interval) {
    const { data } = await authenticatedAxios().get(
      `${process.env.avenueApiUrl}/_avenue_services_/developers/dashboard/stats?statsFor=${interval}`
    );
    commit('stats', data)
  },
  async loadApiKeys({ commit }) {
    const { data } = await authenticatedAxios().get(
      `${process.env.avenueApiUrl}/_avenue_services_/developers/dashboard/apiKeys`
    );

    const apiKeys = {
      icons: data.icons,
      photos: data.photos,
      music: data.music,
      vectors: data.vectors,
      upscaler: data.upscaler,
      bgRemover: data.bgRemover
    };

    commit('apiKeys', apiKeys)
  }
};
