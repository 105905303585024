export const state = () => ({
  apiKey: null
});

export const mutations = {
  API_KEY(state, key) {
    state.apiKey = key;
  }
}

export const actions = {
  setApiKey({ commit }, key) {
    commit('API_KEY', key);
  }
}
