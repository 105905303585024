// this is not passed in from other locations, because nuxt-axios is horrible and does some weird
// non-transparent magic, and I couldn't call `create` for some reason
import axios from 'axios'
import { getCookie } from '@icons8/frontend-utils'

/**
 * Get the icons8 token (currently from a cookie).
 * TODO: handle error when there is nothing in the cookie
 */
function getToken () {
  if (process.client) {
    const token = getCookie('i8token')
    if (token && token.length > 0) {
      return token
    }
    return null
  } else {
    throw new Error('Nah, not doing that in server mode')
  }
}

/**
 * Make an authenticated axios instance from a passed-in one.
 * @returns {import('axios').AxiosInstance}
 */
export function authenticatedAxios () {
  return axios.create({
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  })
}
