'use strict';

export default ((_ref, inject) => {
  let {
    app
  } = _ref;
  if (!process.browser) return;

  // Этот плагин выполняет роль заглушки, и заменяет vue-analytics
  // https://matteogabriele.gitbooks.io/vue-analytics/content/docs/event-tracking.html
  //
  // Для Google analytics лучше использовать стандартные методы
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits
  const $ga = {
    hasGA: () => !!window.ga && typeof window.ga === 'function',
    event: (category, action, label) => {
      if (!$ga.hasGA()) {
        console.warn('event: google analytics is not defined!');
        return;
      }
      const params = {
        hitType: 'event'
      };
      if (category) params.eventCategory = category;else return;
      if (action) params.eventAction = action;
      if (label) params.eventLabel = label;
      window.ga('send', params);
    },
    page: (page, title, location) => {
      if (!$ga.hasGA()) {
        console.warn('page: google analytics is not defined!');
        return;
      }
      const params = {
        hitType: 'pageview'
      };
      if (page) params.page = page;else return;
      if (title) params.title = title;
      if (location) params.location = location;
      window.ga('send', params);
    },
    require: payload => {
      if (!$ga.hasGA()) {
        console.warn('require: google analytics is not defined!');
        return;
      }
      if (!payload) return;
      window.ga('require', payload);
    },
    ecommerce: {
      addItem: function (params) {
        if (params === void 0) {
          params = {};
        }
        if (!$ga.hasGA()) {
          console.warn('ecommerce.addItem: google analytics is not defined!');
          return;
        }
        if (!params.id || !params.name) return;
        window.ga('ecommerce:addItem', params);
      },
      addTransaction: function (params) {
        if (params === void 0) {
          params = {};
        }
        if (!$ga.hasGA()) {
          console.warn('ecommerce.addTransaction: google analytics is not defined!');
          return;
        }
        if (!params.id) return;
        window.ga('ecommerce:addTransaction', params);
      },
      send: () => {
        if (!$ga.hasGA()) {
          console.warn('ecommerce.send: google analytics is not defined!');
          return;
        }
        window.ga('ecommerce:send');
      },
      addProduct: () => {},
      addImpression: () => {},
      setAction: () => {},
      addPromo: () => {}
    },
    set: () => {},
    query: () => {},
    screenview: () => {},
    time: () => {},
    exception: () => {},
    social: () => {}
  };
  inject('ga', $ga);
});