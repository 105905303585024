import { eq, getResourceWithProduct, gt, lt } from '@/utils/products'
import { getProductsForResource } from '@/utils/licenses'
import { getApiProducts } from '~/api/billing'

export const state = () => ({
  products: null,
  loading: false
})

export const mutations = {
  SET_PRODUCTS (state, data) {
    state.products = data
  },
  SET_LOADING (state, value) {
    state.loading = value
  }
}

export const actions = {
  async getAllProducts ({ commit }) {
    commit('SET_LOADING', true)
    try {
      const { data } = await getApiProducts()
      delete data.success
      commit('SET_PRODUCTS', data)
    } catch (e) {
      console.error(e)
    } finally {
      commit('SET_LOADING', false)
    }
  }
}

export const getters = {
  flatProducts: (state) => Object.values(state.products).flatMap(
    (product) => [...product.month, ...product.year]
  ),
  flatProductType: (state) => (type) => {
    if (state.products && state.products[type]) {
      return [...state.products[type].month, ...state.products[type].year]
    }
    return []
  },
  getResourceByProductAlias: state => (alias) => {
    return Object.values(state.products).find(product => {
      return product.month.find(plan => plan.alias === alias) ||
        product.year.find(plan => plan.alias === alias)
    })
  },

  /**
   * Check if given plan is upgrade of already existing license.
   * @param state
   * @param getters
   * @param rootState
   * @return function(Product): Boolean
   */
  isUpgrade: (state, getters, rootState) => ({ product, resource }) => {
    const products = getProductsForResource(
      resource || getResourceWithProduct(product, Object.values(state.products)),
      rootState.auth.user.licenses
    )
    if (products.length === 0) { return false }
    return products.every(gt(product))
  },

  /**
   * Check if given plan is downgrade of already existing license.
   * @param state
   * @param getters
   * @param rootState
   * @return function({ product: Product, resource: Resource }): Boolean
   */
  isDowngrade: (state, getters, rootState) => ({ product, resource }) => {
    const products = getProductsForResource(
      resource || getResourceWithProduct(product, Object.values(state.products)),
      rootState.auth.user.licenses
    ).filter(el => !eq(product)(el))
    if (products.length === 0) { return false }
    return products.some(lt(product))
  },

  /**
   * Check if given product is current.
   * @param state
   * @param getters
   * @param rootState
   * @return function({ product: Product, resource: Resource }): Boolean
   */
  isCurrent: (state, getters, rootState) => ({ product, resource }) => {
    const products = getProductsForResource(
      resource || getResourceWithProduct(product, Object.values(state.products)),
      rootState.auth.user.licenses
    )

    const compare = (a, b) => lt(a, b) ? 1 : -1

    if (products.length === 0) { return false }
    return eq(products.sort(compare)[0], product)
  }
}
