<template>
  <div class="search">
    <div class="button-container">
      <button @click="onInputClick">
        <img src="/vue-static/search.svg" alt="search" class="search-icon" />
        Search...
      </button>
    </div>
    <search-modal />
  </div>
</template>

<script>
import SearchModal from '~/components/search/SearchModal.vue'

let slashEventListener

export default {
  name: 'AppSearch',
  components: {
    SearchModal
  },
  mounted () {
    slashEventListener = (event) => {
      if (event.key === '/') {
        this.onInputClick()
      }
    }
    document.addEventListener('keypress', slashEventListener)
  },
  unmounted () {
    document.removeEventListener('keypress', slashEventListener)
  },
  methods: {
    onInputClick () {
      this.$modal.show('search')
    }
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  margin-right: 16px;
  margin-bottom: 16px;
}

button {
  padding: 12px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: none;
  flex: 1;
  opacity: 0.3;
  text-align: left;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
  }

  &:active {
    opacity: 0.6;
    color: black;
  }

  .search-icon {
    margin-bottom: -3px;
    margin-right: 6px;
    margin-left: 4px;
  }
}
</style>
