import { getSubscriptionUpdatePreview } from '@icons8/common/src/api/billing'

export const state = () => ({
  isModalOpen: false,
  loading: false,
  productSlug: null,
  product: null,
  error: undefined,

  isConfirmModalOpen: false,
  confirmData: null
})

export const mutations = {
  OPEN_MODAL(state) {
    state.isModalOpen = true;
  },
  CLOSE_MODAL(state) {
    state.isModalOpen = false;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_PRODUCT(state, data) {
    state.productSlug = data.slug
    state.product = data.newProduct;
  },
  RESET_PRODUCT(state) {
    state.productSlug = null;
    state.product = null;
  },

  OPEN_CONFIRM_MODAL(state, data) {
    state.confirmData = data;
    state.isConfirmModalOpen = true;
  },
  CLOSE_CONFIRM_MODAL(state) {
    state.isConfirmModalOpen = false;
    state.confirmData = null;
  }
}

export const actions = {
  openUpgradeModal({ commit, rootState }, productSlug) {
    commit('SET_LOADING', true);
    commit('OPEN_MODAL');
    try {
      const { products } = rootState.products;
      const product = products[productSlug] || null;
      commit('SET_PRODUCT', { newProduct: product, slug: productSlug });
    } catch (e) {
      commit('SET_ERROR', e);
      commit('SET_PRODUCT', null)
    } finally {
      commit('SET_LOADING', false);
    }
  },
  closeUpgradeModal({ commit }) {
    commit('CLOSE_MODAL');
    commit('RESET_PRODUCT');
  },

  /**
   * Get preview of new subscription
   * and open modal with this information.
   * @param commit
   * @param modalData {{
   *   alias: string,
   *   fromYear: boolean,
   *   toYear: boolean,
   *   isDowngrade: boolean
   * }}
   */
  async openConfirmModal({ commit }, modalData) {
    try {
      const { data } = await getSubscriptionUpdatePreview({
        addProducts: [modalData.alias]
      });
      commit('OPEN_CONFIRM_MODAL', {
        ...data.results,
        ...modalData
      });
    } catch (e) {
      throw new Error(e);
    }
  },
  closeConfirmModal({ commit }) {
    commit('CLOSE_CONFIRM_MODAL');
  }
}
