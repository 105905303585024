<template>
  <Sidebar
    :elements="sidebarElements"
    class="sidebar"
  />
</template>

<script>
import { swaggers } from '~/store/swagger'
import { getSwaggerSidebarElement } from '~/utils'
import Sidebar from '~/components/docs/sidebar/Sidebar'

export default {
  name: 'SidebarContainer',
  components: { Sidebar },
  computed: {
    sidebarElements () {
      const output = [
        {
          title: 'Getting Started',
          target: '/docs/getting-started',
          getChildren: () => []
        }
      ]
      for (const slug in swaggers) {
        if (swaggers.hasOwnProperty(slug)) {
          output.push(getSwaggerSidebarElement(slug))
        }
      }
      return output
    }
  }
}
</script>

<style scoped>

</style>
