import {swaggers} from './swagger.js'

export const state = () => ({
  query: null,
  results: []
})

export const mutations = {
  query(state, query) {
    state.query = query
  },
  cleanResults(state) {
    state.results = []
  },
  addResult(state, value) {
    if (value.forQuery === state.query) {
      state.results.push(value)
    }
  }
}

function tokenize(query) {
  return query.toLowerCase().split(' ')
}

function isMatching(tokens, swaggerEntry, pathName, path) {
  const description = path.description && path.description.toLowerCase()
  const summary = path.summary.toLowerCase()
  const swaggerTitle = swaggerEntry.metaTitle.toLowerCase()
  const swaggerDescription = swaggerEntry.metaDescription.toLowerCase()
  pathName = pathName.toLowerCase()
  return tokens.every(token => {
    return [
      description && description.includes(token),
      summary.includes(token),
      swaggerTitle.includes(token),
      swaggerDescription.includes(token),
      pathName.includes(token)
    ].some(x => x)
  })
}

export const actions = {
  async search({dispatch, commit, rootGetters}, query) {
    commit('query', query)
    commit('cleanResults')
    const tokens = tokenize(query)

    const promises = []
    for (const slug in swaggers) {
      const data = rootGetters['swagger/get'](slug)
      for (const pathName in data.paths) {
        for (const method in data.paths[pathName]) {
          const {summary, description} = data.paths[pathName][method]
          if (isMatching(tokens, swaggers[slug], pathName, data.paths[pathName][method])) {
            commit('addResult', {
              forQuery: query,
              pathName,
              method,
              description,
              summary,
              slug,
              apiTypeTitle: swaggers[slug].title
            })
          }
        }
      }
    }

    await Promise.all(promises)
  }
}
