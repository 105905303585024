/**
 * Tapfiliate integration.
 *
 * Should be called on any page that is loaded after coming from outside (i.e. should not be called
 * when navigating within the site itself)
 */

import Vue from 'vue'

/* Dedupe. Since this is client-only, module variable is ok */
let promise = null

export function registerTapfiliatePlugin (tapfiliateId) {
  if (typeof window === 'undefined') return Promise.resolve()
  if (promise) return promise

  promise = new Promise((resolve, reject) => {
    const tapfilateScript = document.createElement('script')
    tapfilateScript.type = 'text/javascript'
    tapfilateScript.src = 'https://script.tapfiliate.com/tapfiliate.js'
    tapfilateScript.async = true

    /* Attach callback */
    tapfilateScript.onload = () => {
      window.tap('create', tapfiliateId, { integration: 'stripe' })
      window.tap('detect')
      resolve()
    }

    tapfilateScript.onerror = reject

    document.head.appendChild(tapfilateScript)
  })

  return promise
}

export default () => {
  const tapfiliateId = '13919-7c3a25'

  /* Register vue plugin */
  Vue.use({
    install () {
      registerTapfiliatePlugin(tapfiliateId)
    }
  })
}
