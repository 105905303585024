import { swaggers } from '~/store/swagger'
import { tierColors } from '~/assets/js/constants'

/**
 * Get an example object from the provided schema, using hardcoded defaults, or default values from
 * schema.
 * @param {object} schema json schema
 * @returns {object | null} example object or null
 */
export function getJSONSchemaExample (schema) {
  if (!schema) { return null }
  if (schema.example) return schema.example
  if (schema.default) return schema.default
  switch (schema.type) {
    case 'boolean':
      return false

    case 'string':
      if (schema.format && schema.format === 'binary') {
        return '<binary data>'
      } else if (schema.enum && Array.isArray(schema.enum)) {
        return schema.enum[0]
      } else {
        return 'example123'
      }

    case 'number':
      return 123

    case 'integer':
      return 123

    case 'array':
      const oneExample = getJSONSchemaExample(schema.items)
      return [oneExample]

    case 'object':
      const example = {}
      for (const key in schema.properties) {
        example[key] = getJSONSchemaExample(schema.properties[key])
      }
      if (schema.additionalProperties) {
        if (typeof schema.additionalProperties === 'object') {
          example.anyOtherKey = getJSONSchemaExample(schema.additionalProperties)
        } else {
          example.anyOtherKey = 'anyOtherValue'
        }
      }
      return example
  }
}

/**
 * Generate a path id from a path name.
 *
 * HTML ids are required to contain only alphanumeric chars and some other stuff, but not '/', so we
 * need to convert.
 *
 * @param {string} method
 * @param {string} pathName
 * @returns {string}
 */
export function getPathId (method, pathName) {
  return [
    method,
    pathName.substring(1)
      .replace(/\//g, '-')
      .replace(/[{}]/g, '')
  ].join('-')
}

/**
 * Generate sidebar swagger element.
 *
 * @param {string} slug this API slug
 * @retuns {object} element for Sidebar component.
 */
export function getSwaggerSidebarElement (slug) {
  const path = '/docs/' + slug
  return {
    title: swaggers[slug].title,
    target: path,
    slug,
    getChildren: (_state, getters) => {
      const output = []
      const currentSwagger = getters['swagger/currentSwagger']
      if (currentSwagger.securityDefinitions) {
        output.push({
          target: {
            path,
            hash: '#security-definitions'
          },
          title: 'Security definitions'
        })
      }
      for (const pathName in currentSwagger.paths) {
        if (currentSwagger.paths.hasOwnProperty(pathName)) {
          for (const method in currentSwagger.paths[pathName]) {
            if (currentSwagger.paths[pathName].hasOwnProperty(method)) {
              const { summary: title, description } = currentSwagger.paths[pathName][method]
              output.push({
                target: {
                  path,
                  hash: '#' + getPathId(method, pathName)
                },
                title,
                description
              })
            }
          }
        }
      }
      return output
    }
  }
}

/**
 * Basically, lodash's `get` function.
 * Returns a property of `obj` accessed through a dotted `path`.
 *
 * Examples:
 * ```
 * const obj = { a: 1, b: { c: 'd' } }
 *
 * get(obj, 'a') == 1
 * get(obj, 'b.c') == 'd'
 * get(obj, 'h') == undefined
 * get(obj, 'h.j') == undefined
 * ```
 *
 * @param {object} obj target object
 * @param {string} path dotted path
 */
export function get (obj, path) {
  for (const part of path.split('.')) {
    if (!obj) return undefined
    obj = obj[part]
  }
  return obj
}

/**
 * Generate a GenericProperty element, which explains `additionalProperties`.
 * @param {any} obj schema.additionalProperties definition. If `object`, then it would specify the
 *                  type of additional properties
 * @returns {object} GenericProperty arg
 */
export function getSwaggerAdditionalPropertiesElement (obj) {
  if (typeof obj !== 'object') {
    obj = {}
  }
  return {
    ...obj,
    name: 'anyOtherKey',
    description: 'This object has `additionalProperties` set.\n\n' +
                 'It means that in addition to properties described above it can have\n' +
                 'other properties with any name and value (of the specified type).'
  }
}

/**
 * Get the first non-empty string (meaning containing at least one non-whitespace character) from an
 * array of strings.
 *
 * @param {Array<string>} strings
 * @returns {string | undefined}
 */
export function firstNonEmptyString (strings) {
  return strings.find(x => x && /\S/.test(x))
}

/**
 * Convert price object to price string.
 * @param value {number}
 * @param currency {string}
 * @return {string}
 */
export function toCurrencyString ({ value, currency }) {
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency,
    maximumFractionDigits: 3,
    minimumFractionDigits: 0
  })
}

export function getMetaCallsLimitFor (product, limits) {
  return getLimitsFor(product, limits).find(el => isMetaLimit(el)) ||
    { value: 'Unlimited', types: [] }
}

export function getMainCallsLimitFor (product, limits) {
  return getLimitsFor(product, limits).find(el => !isMetaLimit(el))
}

export function findProductTierByAlias (product, alias, yearly) {
  const term = yearly ? 'year' : 'month'
  return product[term].find(el => el.alias === alias)
}

/**
 * Пиздец костыль, СРОЧНО выпилить.
 * @param currentTierAlias {string}
 * @param thisTierAlias {string}
 * @param product {{ month: [object], year: [object] }}
 * @param yearly {boolean}
 * @param isCurrentYearly {boolean}
 * @return {boolean}
 */
export function isDowngrade (
  currentTierAlias,
  thisTierAlias,
  product,
  yearly,
  isCurrentYearly
) {
  if (!currentTierAlias) { return false }
  if (!yearly && isCurrentYearly) { return true }

  const tiers = yearly ? product.year : product.month

  const currentTierIdx = tiers.findIndex(el => el.alias === currentTierAlias)
  if (currentTierIdx === -1) { return false }

  const thisTierIdx = tiers.findIndex(el => el.alias === thisTierAlias)
  if (thisTierIdx === -1) { return false }

  return thisTierIdx < currentTierIdx
}

/**
 * Get colors for tier badge.
 * @param tierName {string}
 * @return {{backgroundColor: string, color: string}}
 */
export function getTierBadgeColors (tierName) {
  if (tierName) {
    return tierColors[tierName.toLowerCase()]
  }
  return tierColors.trial
}

/**
 * String in format <Currency><Amount>/"month|year"
 * @param price {object}
 * @param yearly {boolean}
 * @return {string}
 */
export function displayPrice (price, yearly) {
  const val = toCurrencyString(price)
  return yearly ? `${val}/year` : `${val}/month`
}

function findIntersectedProduct (productsA, productsB) {
  if (!productsA || !productsB) { return [] }
  return productsA.find(e1 => productsB.find(e2 => e1.alias === e2.alias))
}

/**
 * Get user products with slug.
 * @param userProducts {object[]}
 * @param allProducts {object}
 * @return {object}
 */
export function mapUserProductsOnProducts (userProducts, allProducts) {
  const res = {}
  for (const productSlug in allProducts) {
    if (allProducts.hasOwnProperty(productSlug)) {
      const monthProduct = findIntersectedProduct(userProducts, allProducts[productSlug].month)
      const yearProduct = findIntersectedProduct(userProducts, allProducts[productSlug].year)
      if (monthProduct) { res[productSlug] = monthProduct }
      if (yearProduct) { res[productSlug] = yearProduct }
    }
  }
  return res
}

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * Debounce async function.
 * @param fn {function}
 * @param delay {number}
 * @return {() => Promise<unknown>}
 */
export function debounce (fn, delay) {
  return async args => {
    await timeout(delay)
    return fn(args)
  }
}

/**
 * Generate query string from given params object.
 * @param params {object}
 * @return {string}
 */
export function getQueryString (params) {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')
}

/**
 * @type { {accountingType: String} } limit
 * @return Boolean
 */
export function isMetaLimit (limit) {
  return limit.accountingType.includes('meta')
}

/**
 * @type {string} product
 * @type {[]} limits
 * @return {[]}
 */
export function getLimitsFor (product, limits) {
  return limits.filter(el => product.includes(el.resource))
}

export const getPlanFromProduct = (product) => (alias) => {
  return product.month.find(plan => plan.alias === alias) ||
    product.year.find(plan => plan.alias === alias)
}

export function curry (func) {
  return function curried (...args) {
    if (args.length >= func.length) {
      return func.apply(this, args)
    } else {
      return function (...args2) {
        return curried.apply(this, args.concat(args2))
      }
    }
  }
}
