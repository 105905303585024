import { get, firstNonEmptyString } from '~/utils'
// import { getFromCache, setToCache } from "~/api/cachedApi";
import { getProjectDocs } from "~/api/swaggers";

/**
 * @class SwaggerDef
 * @property {string} title
 * @property {string} pic
 * @property {string} description
 * @property {string} [statuspageComponentId]
 * @property { (x: any) => Promise<object> } getSwagger
 */

/** @type {Array<SwaggerDef>} */
export const swaggers = {
  searchIcons: {
    title: 'Icons Search',
    metaTitle: 'Icons Search - Icons8 Resources for Developers',
    metaDescription: 'Icons Search API gives you access to the search queries in the growing library of diverse graphics in 30+ design styles, created by the Icons8 team.',
    pic: '/vue-static/getting-started/icons.svg',
    uptimeRobotId: 785016953,
    async getSwagger() {
      const { data } = await getProjectDocs('searchIcons');
      return data;
    }
  },
  icons: {
    title: 'Icons',
    metaTitle: 'Icons - Icons8 Resources for Developers',
    metaDescription: 'Icons API gives you access to the growing library of diverse graphics in 30+ design styles, created by Icons8 team.',
    pic: '/vue-static/getting-started/icons.svg',
    uptimeRobotId: 784342804,
    async getSwagger() {
      const { data } = await getProjectDocs('icons');
      return data
    }
  },
  photos: {
    title: 'Photos',
    metaTitle: 'Photos - Icons8 Resources for Developers',
    metaDescription: 'Icons8 Photos API gives you access to the growing library of high-quality stock photos of people, objects, environments, and backgrounds.',
    pic: '/vue-static/getting-started/photos.svg',
    uptimeRobotId: 784343526,
    async getSwagger() {
      const { data } = await getProjectDocs('photos');
      return data
    }
  },
  music: {
    title: 'Music',
    metaTitle: 'Music - Icons8 Resources for Developers',
    metaDescription: 'Icons8 Music API gives you access to the growing library of high-quality music tracks covering different themes, genres, and styles.',
    pic: '/vue-static/getting-started/music.svg',
    uptimeRobotId: 784343529,
    async getSwagger() {
      const { data } = await getProjectDocs('music');
      return data
    }
  },
  vectors: {
    title: 'Vectors',
    metaTitle: 'Vectors - Icons8 Resources for Developers',
    metaDescription: 'Icons8 Vectors API gives you access to the growing library of vector illustrations on popular themes in 30+ design styles.',
    pic: '/vue-static/getting-started/vectors.svg',
    uptimeRobotId: 784343533,
    async getSwagger() {
      const { data } = await getProjectDocs('vectors');
      return data
    }
  },
  upscaler: {
    title: 'Upscaler',
    metaTitle: 'Smart Upscaler - Icons8 Resources for Developers',
    metaDescription: 'Smart Upscaler API gives you an access to the fully-automated process of sharpening, noise reduction and upscaling images up to 3000 x 3000 px.',
    pic: '/vue-static/getting-started/upscaler.svg',
    uptimeRobotId: 786084079,
    async getSwagger() {
      const { data } = await getProjectDocs('upscaler');
      return data;
    }
  },
  bgRemover: {
    title: 'Background Remover',
    metaTitle: 'Background Remover - Icons8 Resources for Developers',
    metaDescription: 'Integrate background removal directly into your products. Send your images in and get back neatly clipped transparent PNG files.',
    pic: '/vue-static/getting-started/bgremover.svg',
    uptimeRobotId: 786679255,
    async getSwagger() {
      const { data } = await getProjectDocs('bgRemover');
      return data;
    }
  }

};

/**
 * Inline any existing `$ref: '#something'` properties throughout a target object.
 *
 * @param {object} swagger swagger instance
 * @param {object} target object
 * @param {string[]} [usedRefs] - already used refs.  Prevents infinite recursion
 */
function inlineRefs(swagger, target, usedRefs = []) {
  if (target.$ref && !usedRefs.includes(target.$ref)) {
    const content = get(swagger, target.$ref.replace(/\//g, '.').substring(2));
    target = Object.assign(target, content);
    usedRefs.push(target.$ref);
    target.$ref = undefined;
  }
  if (typeof target === 'object') {
    for (const prop in target) {
      if (Object.prototype.hasOwnProperty.call(target, prop) && target[prop])
        target[prop] = inlineRefs(swagger, target[prop], usedRefs);
    }
  }
  return target;
}

export async function getSwagger(slug) {
  const swagger = await swaggers[slug].getSwagger();
  // let swagger = getFromCache(slug);
  // if (swagger === undefined) {
  //   swagger = await swaggers[slug].getSwagger();
  //   setToCache(slug, swagger);
  // }

  for (const pathName in swagger.paths) {
    for (const method in swagger.paths[pathName]) {
      const path = swagger.paths[pathName][method];

      if (!path.summary && path.description && /\S/.test(path.description)) {
        path.summary = path.description;
        path.description = undefined
      }

      path.summary = firstNonEmptyString([path.summary, path.operationId, pathName]);

      inlineRefs(swagger, path)
    }
  }
  return swagger
}

export const state = () => ({
  swaggers: {},
  currentSwaggerSlug: 'none',

  /** chosen response code in example box */
  chosenResponse: {},
});

export const mutations = {
  currentSwagger(state, slug) {
    state.currentSwaggerSlug = slug
  },
  swagger(state, { slug, swagger }) {
    state.swaggers[slug] = swagger
  },
  chooseResponse(state, { pathName, method, chosenResponse }) {
    state.chosenResponse[pathName] = state.chosenResponse[pathName] || {};
    state.chosenResponse[pathName][method] = chosenResponse
  }
};

export const actions = {
  async preload({ state, commit }, name) {
    if (state.swaggers[name]) {
      return
    }
    try {
      const swagger = await getSwagger(name);
      commit('swagger', { slug: name, swagger })
    } catch (e) {
      console.log(e)
    }
  },
  load({ state, dispatch, commit }, name) {
    // await dispatch('preload', name);
    const swagger = state.swaggers[name];
    for (const pathName in swagger.paths) {
      for (const method in swagger.paths[pathName]) {
        const path = swagger.paths[pathName][method];
        const defaultResponse = Object.keys(path.responses)[0];
        commit('chooseResponse', {
          pathName,
          method,
          chosenResponse: defaultResponse
        })
      }
    }
    commit('currentSwagger', name)
  }
};

export const getters = {
  currentSwagger: state => state.swaggers[state.currentSwaggerSlug],
  get: state => slug => state.swaggers[slug],

  baseUrl: state => {
    const swagger = state.swaggers[state.currentSwaggerSlug];
    const scheme = (Array.isArray(swagger.schemes) && swagger.schemes[0]) || 'https';
    const host = swagger.host;
    const prefix = swagger.basePath || '';

    if (!host) {
      return ''
    }

    return `${scheme}://${host}${prefix}`
  },

  /**
   * @param {object} state
   * @returns {{ prev: { title: string, slug: string }, next: { title: string, slug: string } }}
   */
  adjacent: state => {
    // nah, would probably optimize later
    const keys = Object.keys(swaggers);
    if (keys[keys.length - 1] === state.currentSwaggerSlug) {
      return {
        prev: {
          title: swaggers[keys[keys.length - 2]].title,
          slug: keys[keys.length - 2]
        }
      }
    }
    let prev, next;
    for (let i = 0; i < keys.length - 1; i++) {
      if (keys[i] === state.currentSwaggerSlug) {
        if (i === 0) {
          prev = {
            title: 'Getting started',
            slug: 'getting-started'
          }
        } else {
          prev = {
            title: swaggers[keys[i - 1]].title,
            slug: keys[i - 1]
          }
        }
        next = {
          title: swaggers[keys[i + 1]].title,
          slug: keys[i + 1]
        }
      }
    }
    return {prev, next};
  }
};
