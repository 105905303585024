<template>
  <hr class="divider" />
</template>

<script>
export default {
  name: 'Divider'
}
</script>

<style lang="scss" scoped>
.divider {
  border: 0 solid rgba(0, 0, 0, 0.1);
  border-top-width: 1px;
}
</style>
